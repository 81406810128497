'use client';

import { createContext, ReactNode, useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import useLocalStorage from '@web/context/localstorage';
import axios from 'axios';

const AnalyticsContext = createContext<any>(undefined!);

type Props = {
  children: ReactNode;
};
const USER_ID_KEY = 'bw_user_id';
const isLocal = () => location.hostname === 'localhost';
export const AnalyticsProvider = ({ children }: Props) => {
  const router = useRouter();
  const [userId] = useLocalStorage(USER_ID_KEY, '');
  const analytics: any = {};

  useEffect(() => {
    const handleRouteChange = () => {
      if (userId && !isLocal()) {
        axios
          .post('/api/analytics/page', {
            userId,
            path: location.pathname,
            pageUrl: location.href,
            pageTitle: document.title,
          })
          .then();
      }
    };

    handleRouteChange();

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, userId]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const trackOfferClick = useCallback((url) => {
    const userId = localStorage.getItem(USER_ID_KEY);
    !isLocal() &&
      axios
        .post('/api/analytics/offer_click', {
          userId,
          path: location.pathname,
          pageUrl: location.href,
          pageTitle: document.title,
          offerUrl: url,
        })
        .then();
  }, []);

  const trackSearch = useCallback((search) => {
    const userId = localStorage.getItem(USER_ID_KEY);
    !isLocal() &&
      axios
        .post('/api/analytics/search', {
          userId,
          path: location.pathname,
          pageUrl: location.href,
          pageTitle: document.title,
          search,
        })
        .then();
  }, []);

  const trackProductRequest = useCallback(() => {}, []);

  return {
    trackOfferClick,
    trackSearch,
    trackProductRequest,
  };
};
